module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-100358680-5"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"陈惠超个人博客","short_name":"博客","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/icon.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-source-notion-database-private/gatsby-browser.js'),
      options: {"plugins":[],"sourceConfig":[{"name":"posts","table":"https://www.notion.so/superman66/4161940d761746439447a57cff0e9784?v=ce2b253e666e4a7d9b8934e26128455e","cacheType":"html"},{"name":"books","table":"https://www.notion.so/superman66/4e040d5477a844949980e0d6b8fd3294?v=741112868e364c92ad2eb864507615ef","cacheType":"static"}]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
